<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                    <template v-slot:projectNameSlot>
                    {{ }}
                    </template>
                    {{ $t('globalTrans.payment') }}
                </list-report-head>
                <b-row>
                    <b-table-simple v-if="circular_type === 1" bordered small>
                        <thead>
                            <tr>
                                <b-th colspan="7" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                            </tr>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('ditfConfig.stall_no') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.security_money') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.processing_fee') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <span hidden>{{ $si = 1 }}</span>
                            <template v-for="(items, index) in stalls">
                                <b-tr v-for="(item, index1) in items" :key="index+index1">
                                    <b-td v-if="index1 === 0" class="align-middle text-center" :rowspan="items.length">{{ $n($si++) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{ getStallCategoryName(index) }}</b-td>
                                    <b-td class="text-center">{{ $n(item.stall_info.stall_no, {useGrouping: false}) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{ $n(getFloorPrice(index), { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td class="text-center">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{ $n(getSecurityMoney(items[0]['quoted_price']), { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{$n(getProcessFee(index), { minimumFractionDigits: 2 }) }}</b-td>
                                </b-tr>
                            </template>
                            <b-tr>
                                <b-td colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                <b-td class="text-center">{{ $n(getTotalSMoney(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="text-center">{{ $n(getTotalProcessFee(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="5" class="text-right">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                                <b-td colspan="2" class="text-center">{{ $n((totalSecurityMoney + totalProcessFee), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple v-if="circular_type === 2" bordered small>
                        <thead>
                            <tr>
                                <b-th colspan="5" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                            </tr>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.processing_fee') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <span hidden>{{ $si = 1 }}</span>
                            <b-tr v-for="(item, index) in stalls" :key="index">
                                <b-td class="align-middle text-center">{{ $n($si++) }}</b-td>
                                <b-td class="align-middle text-center">{{ getStallCategoryName(item.stall_cat_id) }}</b-td>
                                <b-td class="align-middle text-center">{{ $n(getFloorPrice(item.stall_cat_id), { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="text-center">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="align-middle text-center">{{$n(getProcessFee(item.stall_cat_id), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="3" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                <b-td class="text-center">{{ $n(getQuotedPrice(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="text-center">{{ $n(getOTotalProcessFee(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="3" class="text-right">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                                <b-td colspan="2" class="text-center">{{ $n((totalSecurityMoney + totalProcessFee), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-row>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                        <b-row>
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider :name="$t('externalTradeFair.bank_deposit', 'en')" vid="voucher_no" rules="required" v-slot="{ errors }">
                                    <b-form-group label-for="voucher_no" label-cols-sm="4">
                                        <template v-slot:label>
                                            {{$t('externalTradeFair.bank_deposit')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            plain
                                            v-model="payment.voucher_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider :name="$t('globalTrans.date', 'en')" vid="pay_date" rules="required" v-slot="{ errors }">
                                    <b-form-group class="row mb-2"
                                    label-cols-sm="4"
                                    label-for="pay_date"
                                    >
                                    <template v-slot:label>
                                        {{ $t('globalTrans.date') }} <span class="text-danger">*</span>
                                    </template>
                                    <date-picker
                                        id="pay_date"
                                        v-model="payment.pay_date"
                                        class="form-control"
                                        :placeholder="$t('globalTrans.select_date')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                    >
                                    </date-picker>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider :name="$t('externalUser.bank_name', 'en')" vid="bank_id" rules="required|min_value:1" v-slot="{ errors }">
                                    <b-form-group label-for="bank_id" label-cols-sm="4">
                                        <template v-slot:label>
                                            {{$t('externalUser.bank_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="payment.bank_id"
                                            :options="sortedBankList"
                                            @change="getBranchList(payment.bank_id)"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider :name="$t('externalUser.branch_name', 'en')" vid="branch_name" rules="required" v-slot="{ errors }">
                                    <b-form-group label-for="branch_name" label-cols-sm="4">
                                        <template v-slot:label>
                                            {{$t('externalUser.branch_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            plain
                                            v-model="payment.branch_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider :name="$t('externalUser.branch_name', 'en')" vid="branch_id" rules="required|min_value:1" v-slot="{ errors }">
                                    <b-form-group label-for="branch_id" label-cols-sm="4">
                                        <template v-slot:label>
                                            {{$t('externalUser.branch_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="payment.branch_id"
                                            :options="branchList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col> -->
                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <ValidationProvider :name="$t('externalTradeFair.check_deposit', 'en')" vid="attachment" rules="required" v-slot="{ errors }">
                                    <b-form-group class="row mb-2"
                                        label-cols-sm="4"
                                        label-for="attachment">
                                        <template v-slot:label>
                                            {{ $t('externalTradeFair.check_deposit')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-file
                                            plain
                                            v-on:change="onFileChange($event)"
                                            v-model="attachment_input"
                                            accept=".jpg,.jpeg,.pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-file>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        <div class="invalid-feedback d-block text-muted">
                                            {{ $i18n.locale == 'en' ? '[Maximum file size is 500 KB and file format is JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 500 KB এবং ফাইল ফরমেট JPG/JPEG/PDF]' }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                                <b-button type="button" @click="finalSave" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.payment') }}</b-button>
                                <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-payment')">{{ $t('globalTrans.cancel') }}</b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { DitfPaymentDetails, DitfPaymentStore } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
    name: 'Form',
    props: ['id', 'circular_type', 'year'],
    components: {
        ListReportHead
    },
    created () {
        if (this.id) {
            this.getPayInfo()
            this.getFairBankId()
        }
    },
    data () {
        return {
            baseUrl: internationalTradeFairServiceBaseUrl,
            loader: false,
            valid: null,
            totalSecurityMoney: 0,
            totalProcessFee: 0,
            // totalMoney: totalSecurityMoney + totalProcessFee,
            stalls: [],
            branchList: [],
            payment: {
                app_main_id: this.id,
                total_app_fee: this.totalProcessFee,
                total_sec_money: this.totalSecurityMoney,
                total_money: this.totalProcessFee + this.totalSecurityMoney,
                voucher_no: '',
                bank_id: 0,
                branch_id: 0,
                attachment: '',
                pay_date: ''
            },
            details: [],
            sortedBankList: [],
            attachment_input: []
        }
    },
    watch: {
    },
    computed: {
        bankList () {
            return this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
        },
        currentLocale () {
        return this.$i18n.locale
        }
    },
    methods: {
        getBranchList (Id) {
            this.branchList = this.$store.state.CommonService.commonObj.branchList.filter(item => item.bank_id === parseInt(Id))
        },
        getFloorPrice (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            return Obj !== undefined ? Obj.floor_price : ''
        },
        getProcessFee (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            return Obj !== undefined ? Obj.processing_fee : 0
        },
        getStallCategoryName (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            if (this.currentLocale === 'bn') {
            return Obj !== undefined ? Obj.text_bn : ''
            } else {
            return Obj !== undefined ? Obj.text_en : ''
            }
        },
        getSecurityMoney (data) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.year)
            const percentage = Obj !== undefined ? Obj.percentage : 0
            const amount = (parseFloat(percentage) * parseFloat(data)) / 100
            return parseFloat(amount)
        },
        getQuotedPrice (items) {
            if (items) {
                let quotedPrice = 0
                items.map((item) => {
                    quotedPrice += parseInt(item.quoted_price)
                })
                this.totalSecurityMoney = quotedPrice
                return quotedPrice
            }
        },
        getOTotalProcessFee (items) {
            if (items) {
                let processFee = 0
                items.map((item) => {
                    const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(data => parseInt(data.value) === parseInt(item.stall_cat_id))
                    processFee += parseInt(Obj.processing_fee)
                })
                this.totalProcessFee = processFee
                return processFee
            }
        },
        getTotalSMoney (items) {
            if (items) {
                let sMoney = 0
                const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.year)
                const percentage = Obj !== undefined ? Obj.percentage : 0
                Object.keys(items).map((key) => {
                    sMoney += (items[key][0].quoted_price * percentage) / 100
                })
                this.totalSecurityMoney = sMoney
                return sMoney
            }
        },
        getTotalProcessFee (items) {
            if (items) {
                let processFee = 0
                Object.keys(items).map((index) => {
                    const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => item.value === parseInt(index))
                    processFee += parseFloat(Obj.processing_fee)
                })
                this.totalProcessFee = processFee
                return processFee
            }
        },
        async getPayInfo () {
            this.details = []
            this.loader = true
            const myObj = {
                app_main_id: this.id,
                circular_type: this.circular_type
            }
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfPaymentDetails, myObj)
            this.loader = false
            if (result.success) {
                this.stalls = result.data
                if (this.circular_type === 1) {
                    Object.keys(result.data).forEach(el => {
                        this.details.push({
                            stall_cat_id: el,
                            processing_fee: this.getProcessFee(el),
                            security_money: this.getSecurityMoney(result.data[el][0].quoted_price)
                        })
                    })
                }
            }
        },
        onFileChange (event) {
            const input = event.target
            if (input.files && input.files[0]) {
                this.payment.attachment = event.target.files[0]
            }
        },
        finalSave () {
            this.$swal({
                title: this.$t('externalTradeFair.fair_payment_msg'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.payment.total_app_fee = this.totalProcessFee
                    this.payment.total_sec_money = this.totalSecurityMoney
                    this.payment.total_money = (this.totalProcessFee + this.totalSecurityMoney)
                    this.saveUpdate()
                }
            })
        },
        async saveUpdate () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                let result = null
                this.loader = true
                const loadingState = { loading: false, listReload: false }
                const formData = new FormData()
                Object.keys(this.payment).map(key => {
                    if (key === 'attachment') {
                        formData.append(key, this.attachment_input)
                    } else {
                        formData.append(key, this.payment[key])
                    }
                })
                if (this.circular_type === 1) {
                    formData.append('details', JSON.stringify(this.details))
                }

                if (this.id) {
                    result = await RestApi.postData(internationalTradeFairServiceBaseUrl, DitfPaymentStore, formData)
                }
                this.loader = false
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    this.$bvModal.hide('modal-payment')
                } else {
                    this.$refs.form.setErrors(result.errors)
                }
            }
        },
        async getFairBankId () {
            const bankList = this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, '/ditf-configuration/bank-info/details', null)
            if (result.success) {
                this.sortedBankList = bankList.filter(item => result.data.includes(item.value))
            } else {
                this.sortedBankList = []
            }
        }
    }
}
</script>
